import React from 'react';

const NotFoundPage = () => {
  React.useEffect(() => {
    console.log(window.location.pathname);
    if (window.location.pathname !== '/') {
      window.location = 'https://hej.boksnok.se';
    }
  }, []);
  return <div />;
};

export default NotFoundPage;
